import moment from 'moment'
import { Method } from './apiMethods'

export const loginApi = data => Method.POST('admin/login', data)

export const forgotPasswordApi = data =>
  Method.POST('admin/forgotPassword', data)

export const logoutApi = () => Method.GET('admin/logout')

export const changePasswordApi = data =>
  Method.POST('admin/changePassword', data)

export const resetPasswordApi = data => Method.POST('admin/resetPassword', data)

export const resetPasswordLinkApi = data =>
  Method.POST('admin/resetPasswordLink', data)

export const userListApi = data => Method.POST('admin/userList', data)

export const interestListApi = data =>
  Method.GET('user/intrestList?userId=' + data)

export const viewApi = data => Method.GET('admin/userDetails/' + data)

export const userEditApi = data => Method.POST('admin/userDetails', data)

export const removeMemberApi = data => Method.POST('admin/removeMember', data)

export const editApi = data => Method.PUT('admin/editUser', data)

export const editEventApi = data => Method.PUT('event/edit', data)

export const bloackApi = data => Method.GET(data)

export const viewEventDetailApi = data => Method.POST('event/detail', data)

export const deleteEventDetailApi = data =>
  Method.DELETE('event/delete/' + data)

export const approveRejectEventApi = data =>  Method.POST("admin/approve-event",data)

export const dashboardApi = data => Method.GET(`admin/dashboard?date=${moment.utc(moment().startOf('day')).format('YYYY-MM-DDTHH:mm:ssZ')}`)

export const eventListApi = data => Method.POST('event/list', data)

export const subscriptionApi = data =>
  Method.GET('user/subscriptions?userId=' + data)

export const verifyAccountApi = data => Method.POST('user/verifyEmail', data)

export const timezoneApi = data => Method.POST('user/timezone', data)

export const createEventApi = data => Method.POST('event/create', data)

export const postListApi = data => Method.POST('admin/postList', data)

export const postBlockApi = data => Method.GET('admin/changePostStatus/' + data)

export const DeletePostApi = data => Method.DELETE('admin/deletePost/' + data)

export const postDetailsApi = data => Method.GET('admin/postDetails/' + data)

export const postUpdateApi = data => Method.PUT('admin/editPost', data)

export const userAddressApi = data => Method.GET('user/states', data)

export const eventparticipantsApi = data =>
  Method.POST('event/participants', data)


export const changeRoleApi = data =>
  Method.POST('admin/makeAmbassador', data)

  export const AddMember = data =>
  Method.POST('admin/create-user', data)



  export const deleteUserApi = data =>
  Method.POST('admin/user-delete/',  data)