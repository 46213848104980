import { put, call, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  approveRejectEventApi,
  createEventApi,
  deleteEventDetailApi,
  editEventApi,
  eventListApi,
  eventparticipantsApi,
  timezoneApi,
  viewEventDetailApi,
} from "../axios/axiosApi";
import ApiConstants from "../../themes/apiConstants";
import { navigate } from "../../Route/navigationServices";
import ValidationConstants from "../../themes/validationConstants";
import { constantRoutes } from "../../constants/constantRoutes";
import { sessionOut } from "./authSaga";

function* eventParticipantsSaga(action) {
  try {
    let response = yield call(eventparticipantsApi, action.payload);
    let { result, status } = response;

    if (status === 1) {
      yield put({
        type: ApiConstants.API_PARTICIPANTS_SUCCESS,
        payload: result.data,
      });
    } else if (status === 2) {
      yield call(sessionOut, result?.msg);
    } else {
      toast(result?.msg, { toastId: "userList-passowrd-error" });
    }
  } catch (error) {
    toast(ValidationConstants.internetCheck);
  }
}

function* createEventSaga(action) {
  try {
    let response = yield call(createEventApi, action?.payload);
    let { result, status } = response;

    if (status === 1) {
      navigate(constantRoutes.eventmanagement);
      toast(result?.message);
    } else if (status === 2) {
      yield call(sessionOut, result?.msg);
    } else {
      toast(result?.msg, { toastId: "Change-passowrd-error" });
    }
  } catch (error) {
    toast(ValidationConstants.internetCheck);
  }
}

function* eventListSaga(action) {
  try {
    let response = yield call(eventListApi, action.payload);
    let { result, status } = response;

    if (status === 1) {
      yield put({
        type: ApiConstants.API_EVENTLIST_SUCCESS,
        payload: result?.data,
      });
    } else if (status === 2) {
      yield call(sessionOut, result?.msg);
    } else {
      toast(result?.msg, { toastId: "userList-passowrd-error" });
    }
  } catch (error) {
    toast(ValidationConstants.internetCheck);
  }
}

function* eventViewSaga(action) {
  try {
    let response = yield call(viewEventDetailApi, action.payload);
    let { result, status } = response;

    if (status === 1) {
      yield put({
        type: ApiConstants.API_EVENTVIEW_SUCCESS,
        payload: result.data,
      });
    } else if (status === 2) {
      yield call(sessionOut, result?.msg);
    } else {
      toast(result?.msg, { toastId: "userview-error" });
    }
  } catch (error) {
    toast(ValidationConstants.internetCheck);
  }
}

function* editEventSaga(action) {
  try {
    let response = yield call(editEventApi, action.payload);
    let { result, status } = response;

    if (status === 1) {
      toast(result?.message, { toastId: "success-error" });
      navigate(constantRoutes.eventmanagement);
    } else if (status === 2) {
      yield call(sessionOut, result?.msg);
    } else {
      toast(result?.msg, { toastId: "userview-error" });
    }
  } catch (error) {
    toast(ValidationConstants.internetCheck);
  }
}

function* timezoneSaga(action) {
  try {
    let response = yield call(timezoneApi, action.payload);
    let { result, status } = response;

    if (status === 1) {
      yield put({
        type: ApiConstants.API_TIMEZONE_SUCCESS,
        payload: result?.data,
      });
    } else if (status === 2) {
      yield call(sessionOut, result?.msg);
    } else {
      yield put({
        type: ApiConstants.API_RESETLINK_SUCCESS,
        payload: { onLoad: false, userData: result?.msg },
      });
    }
  } catch (error) {
    toast(ValidationConstants.internetCheck);
  }
}

function* deleteEventSaga(action) {
  try {
    let response = yield call(deleteEventDetailApi, action.payload[0]);
    let { result, status } = response;

    if (status === 1) {
      toast(result?.message, { toastId: "success-error" });
      yield call(eventListSaga, { payload: action.payload[1] });
    } else if (status === 2) {
      yield call(sessionOut, result?.msg);
    } else {
      toast(result?.msg, { toastId: "userview-error" });
    }
  } catch (error) {
    toast(ValidationConstants.internetCheck);
  }
}

function* approveRejectEventSaga(action) {
  try {
    let response = yield call(approveRejectEventApi, action.payload[0]);
    let { result, status } = response;

    if (status === 1) {
      toast(result?.message, { toastId: "success-error" });
      yield call(eventListSaga, { payload: action.payload[1] });
    } else if (status === 2) {
      yield call(sessionOut, result?.msg);
    } else {
      toast(result?.msg, { toastId: "userview-error" });
    }
  } catch (error) {
    toast(ValidationConstants.internetCheck);
  }
}

export default function* rootEventSaga() {
  yield takeLatest(ApiConstants.API_PARTICIPANTS_LOAD, eventParticipantsSaga);
  yield takeLatest(ApiConstants.API_CREATEEVENT_LOAD, createEventSaga);
  yield takeLatest(ApiConstants.API_EVENTLIST_LOAD, eventListSaga);
  yield takeLatest(ApiConstants.API_EVENTVIEW_LOAD, eventViewSaga);
  yield takeLatest(ApiConstants.API_EVENTEDIT_LOAD, editEventSaga);
  yield takeLatest(ApiConstants.API_EVENTDELETE_LOAD, deleteEventSaga);
  yield takeLatest(ApiConstants.API_APPROVE_REJECT_EVENT_LOAD, approveRejectEventSaga);

  yield takeLatest(ApiConstants.API_TIMEZONE_LOAD, timezoneSaga);
}
