const ApiConstants = {
  API_APPROVE_REJECT_EVENT_LOAD:"API_APPROVE_REJECT_EVENT_LOAD",
  API_APPROVE_REJECT_EVENT_SUCCESS:"API_APPROVE_REJECT_EVENT_SUCCESS",
  API_APPROVE_REJECT_EVENT_ERROR:"API_APPROVE_REJECT_EVENT_ERROR",
  API_LOGIN_SUCCESS: 'API_LOGIN_SUCCESS',
  UPDATE_LODING_STATE: 'UPDATE_LODING_STATE',
  API_CREATE_ROOM_SUCCESS: 'API_CREATE_ROOM_SUCCESS',
  API_LOGOUT_SUCCESS: 'API_LOGOUT_SUCCESS',
  API_CHANGE_PASSWORD_ERROR: 'API_CHANGE_PASSWORD_ERROR',
  API_CHANGE_PASSWORD_SUCCESS: 'API_CHANGE_PASSWORD_SUCCESS',
  API_LOGOUT_ERROR: 'API_LOGOUT_ERROR',
  API_LOGOUT_FAIL: 'API_LOGOUT_FAIL',
  API_FORGOT_PASSWORD_ERROR: 'API_FORGOT_PASSWORD_ERROR',
  API_FORGOT_PASSWORD_SUCCESS: 'API_FORGOT_PASSWORD_SUCCESS',
  API_LOGIN_ERROR: 'API_LOGIN_ERROR',
  API_SIGNUP_ERROR: 'API_SIGNUP_ERROR',
  API_SIGNUP_SUCCESS: 'API_SIGNUP_SUCCESS',
  API_SIGNUP_LOAD: 'API_SIGNUP_LOAD',
  API_LOGIN_LOAD: 'API_LOGIN_LOAD',
  API_FORGOT_PASSWORD_LOAD: 'API_FORGOT_PASSWORD_LOAD',
  API_LOGOUT_LOAD: 'API_LOGOUT_LOAD',
  API_CHANGE_PASSWORD_LOAD: 'API_CHANGE_PASSWORD_LOAD',
  API_DELETE_ACCOUNT_LOAD: 'API_DELETE_ACCOUNT_LOAD',
  API_UPDATE_PROFILE_SUCCESS: 'API_UPDATE_PROFILE_SUCCESS',
  API_UPDATE_PROFILE_FAIL: 'API_UPDATE_PROFILE_FAIL',
  API_UPDATE_PROFILE_ERROR: 'API_UPDATE_PROFILE_ERROR',
  API_T_AND_C_SUCCESS: 'API_T_AND_C_SUCCESS',
  API_UPDATE_PROFILE_LOAD: 'API_UPDATE_PROFILE_LOAD',
  API_CHECK_FOR_ANY_SUBSCRIPTION_LOAD: 'API_CHECK_FOR_ANY_SUBSCRIPTION_LOAD',
  API_T_AND_C_LOAD: 'API_T_AND_C_LOAD',
  API_RESET_LOAD: 'API_RESET_LOAD',
  API_RESET_SUCCESS: 'API_RESET_SUCCESS',
  API_RESET_ERROR: 'API_RESET_ERROR',
  API_RESETLINK_SUCCESS: 'API_RESETLINK_SUCCESS',
  API_RESETLINK_LOAD: 'API_RESETLINK_LOAD',
  API_USERLIST_LOAD: 'API_USERLIST_LOAD',
  API_USERLIST_SUCCESS: 'API_USERLIST_SUCCESS',
  API_USERLIST_ERROR: 'API_USERLISTERRORD',
  USERLISTSEARCH: 'USERLISTSEARCH',
  PAGEDETIALS: 'PAGEDETIALS',
  API_VIEW_LOAD: 'API_VIEW_LOAD',
  API_VIEW_SUCCESS: 'API_VIEW_SUCCESS',
  API_USEREDIT_LOAD: 'API_USEREDIT_LOAD',
  API_EDIT_LOAD: 'API_EDIT_LOAD',
  VIEWPAGEDETIALS: 'VIEWPAGEDETIALS',
  API_BLOCK_LOAD: 'API_BLOCK_LOAD',
  API_BLOCK_SUCCESSS: 'API_BLOCK_SUCCESSS',
  API_DASHBOARD_LOAD: 'API_DASHBOARD_LOAD',
  API_DASHBOARD_SUCCESS: 'API_DASHBOARD_SUCCESS',
  API_REMOVEMEMBER_LOAD: 'API_REMOVEMEMBER_LOAD',
  API_EVENTLIST_LOAD: 'API_EVENTLIST_LOAD',
  API_EVENTLIST_SUCCESS: 'API_EVENTLIST_SUCCESS',
  API_EVENTVIEW_LOAD: 'API_EVENTVIEW_LOAD',
  API_EVENTVIEW_SUCCESS: 'API_EVENTVIEW_SUCCESS',
  API_EVENTEDIT_LOAD: 'API_EVENTEDIT_LOAD',
  API_EVENTDELETE_LOAD: 'API_EVENTDELETE_LOAD',
  API_SUBSCRIPTION_LOAD: 'API_SUBSCRIPTION_LOAD',
  API_SUBSCRIPTION_SUCCESS: 'API_SUBSCRIPTION_SUCCESS',
  API_VERIFY_ACCOUNT_LOAD: 'API_VERIFY_ACCOUNT_LOAD',
  API_INTEREST_LIST_LOAD: 'API_INTEREST_LIST_LOAD',
  API_INTEREST_LIST_SUCCESS: 'API_INTEREST_LIST_SUCCESS',
  API_PACK_LOAD: 'API_PACK_LOAD',
  API_TIMEZONE_LOAD: 'API_TIMEZONE_LOAD',
  API_TIMEZONE_SUCCESS: 'API_TIMEZONE_SUCCESS',
  API_CREATEEVENT_LOAD: 'API_CREATEEVENT_LOAD',
  API_POST_LIST_LOAD: 'API_POST_LIST_LOAD',
  API_POST_LIST_SUCCESS: 'API_POST_LIST_SUCCESS',
  API_POST_BLOCK_LOAD: 'API_POST_BLOCK_LOAD',
  API_DELETE_POST_LOAD: 'API_DELETE_POST_LOAD',
  API_POST_DETAILS_SUCCESS: 'API_POST_DETAILS_SUCCESS',
  API_POST_DETAILS_LOAD: 'API_POST_DETAILS_LOAD',
  API_POST_UPDATE_LOAD: 'API_POST_UPDATE_LOAD',
  API_USER_ADDRESS_LOAD: 'API_USER_ADDRESS_LOAD',
  API_USER_ADDRESS_SUCCESS: 'API_USER_ADDRESS_SUCCESS',
  API_PARTICIPANTS_SUCCESS: 'API_PARTICIPANTS_SUCCESS',
  API_PARTICIPANTS_LOAD: 'API_PARTICIPANTS_LOAD',
  CHANGE_USER_ROLE_LOAD:"CHANGE_USER_ROLE_LOAD",
  CHANGE_USER_ROLE_SUCCESS:"CHANGE_USER_ROLE_SUCCESS",
  CHANGE_USER_ROLE_FAIL:"CHANGE_USER_ROLE_FAIL",
  API_ADD_LOAD:"API_ADD_LOAD",
  API_ADD_SUCCESS:"API_ADD_SUCCESS",
  API_ADD_FAIL:"API_ADD_FAIL",
  API_DELETE_USER_LOAD:"API_DELETE_USER_LOAD",
  API_DELETE_USER_SUCCESS:"API_DELETE_USER_SUCCESS",
  API_DELETE_USER_FAIL:"API_DELETE_USER_FAIL"

}

export default ApiConstants
