import React, { useState, useEffect, useRef } from "react";
import images from "../../themes/appImage";
import { Image, Select } from "antd";
import appconstant from "../../themes/appconstant";
import Textfield from "../../common";
import TimePicker from "../../common/TimePicket";
import DatePicker from "../../common/DatePicker";
import moment from "moment-timezone";
import { isInternetConnected } from "../../helper/networkUtils";
import { toast } from "react-toastify";
import ValidationConstants from "../../themes/validationConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  constValues,
  extentions,
  time_dateFormat,
} from "../../constants/constantValues";
import { constantRoutes } from "../../constants/constantRoutes";
import { url } from "../../redux/axios/apikit";
import { ValidationFunction } from "../../themes/validationFunctions";
import {
  editEventAction,
  viewEventAction,
} from "../../redux/actions/eventActions.js";
import BreadCrumb from "../../common/breadCrumb";
import { userAddressAction } from "../../redux/actions/userActions";
import { DropDownSelect } from "../../common/DropDownSelect";
import TextAreafield from "../../common/TextAreaField";
import { Option } from "antd/lib/mentions";
import GooglePlacesAutoComplete from "../../common/googlePlacesAutoComplete";

const EditEventDetails = () => {
  const dispatch = useDispatch();
  const refs = useRef();
  const [image, setImage] = useState();
  const [form, setForm] = useState({});
  const [eventFor, setEventFor] = useState('paid')
  const [detail, setDetail] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [latlng, setLatlng] = useState({
    lat: 0,
    lng: 0
  });
  const data = useSelector((state) => state.eventReducer.eventViewData);
  const locations = useSelector(
    (state) => state.userManagementReducer?.location
  );
  const state = locations?.filter(
    (value) => value?._id === detail?.state?._id
  )?.[0]?.name;
  const cities = locations?.filter((value) => {
    if (value?._id === detail?.state?._id) {
      return value?.cities;
    }
  })?.[0]?.cities;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isInternetConnected()) {
      dispatch(
        viewEventAction({
          eventId: JSON.parse(localStorage.getItem("eventId")),
        })
      );
      dispatch(userAddressAction());
    } else {
      toast(ValidationConstants.internetCheck, { toastId: 22 });
    }
  }, []);
  useEffect(() => {
    setForm({
      startTime: moment(data?.startTime)
        .local()
        .format(time_dateFormat.timeFormat),
      duration: moment(data?.duration, "HH:mm").format("YYYY-MM-DDTHH:mm:ss"),
      ...data,
    });
    setDetail(data);
    if (data?.visibleTo) {
      setEventFor(data?.visibleTo)
    }
  }, [data]);


  const update = () => {
    let startDate = moment(form?.startDate).format("YYYY-MM-DD");
    let startTime = moment(form.startTime).format("HH:mm:ssZ");

    let hoursDuration = parseInt(form.duration.split(":")[0]);
    let minuteDuration = form.duration.split(":")[1];
    let START_TIME = moment(`${startDate}T${startTime}`).utc().format("YYYY-MM-DDTHH:mm:ssZ");
    let END_TIME = null;

    if (hoursDuration > 0)
      END_TIME = moment(`${startDate}T${startTime}`).utc().add(hoursDuration, "hours").format("YYYY-MM-DDTHH:mm:ssZ");
    if (minuteDuration > 0)
      END_TIME = moment(`${startDate}T${startTime}`).utc().add(minuteDuration, "minutes").format("YYYY-MM-DDTHH:mm:ssZ");

    const body = new FormData();
    body.append(
      "startDate",
      moment(startDate, "YYYY-MM-DD")
        .startOf("day")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ssZ")
    );
    body.append("endDate", END_TIME);
    body.append("startTime", START_TIME);
    body.append("name", form?.name);
    body.append("eventId", JSON.parse(localStorage.getItem("eventId")));
    body.append("address", form?.address);
    body.append("description", form?.description);
    body.append("duration", form?.duration);
    body.append("visibleTo", eventFor);
    if (latlng.lat && latlng.lng) {
      body.append("latitude", latlng.lat);
      body.append("longitude", latlng.lng);
    }


    if (isInternetConnected()) {
      if (!Object.values(validate()).join() || cities.length > 0) {
        if (image || data?.image) {
          body.append("image", image);
          dispatch(editEventAction(body));
        } else {
          toast(ValidationConstants.profileRequired);
        }
      }
    } else {
      toast(ValidationConstants.internetCheck, { toastId: 23 });
    }
  };

  const inputChange = (value) => {
    setForm((state) => {
      return { ...state, ...value };
    });
  };
  const imageSet = (e) => {
    if (
      e.target.files[0]?.type === extentions.jpeg ||
      e.target.files[0]?.type === extentions.jpg ||
      e.target.files[0]?.type === extentions.png
    ) {
      if (e.target.files[0]?.size < constValues.imageSize) {
        setImage(e.target.files[0]);
      } else {
        toast(ValidationConstants.fileSize);
      }
    } else {
      toast(ValidationConstants.fileExtention);
    }
  };
  const validate = () => {
    const errors = {};
    if (form.name === "") {
      errors.name = ValidationConstants.eventNameRequired;
    } else if (form.name?.[0] === " ") {
      errors.name = ValidationFunction.validField(
        appconstant.eventName.toLocaleLowerCase()
      );
    } else if (form.name?.length < 3) {
      errors.name = ValidationFunction.minlength("Event name");
    }
    if (form.description === "") {
      errors.description = ValidationConstants.descriptionRequired;
    } else if (form.description?.[0] === " ") {
      errors.description = ValidationFunction.validField(
        appconstant.description.toLocaleLowerCase()
      );
    } else if (form.description?.length < 3) {
      errors.description = ValidationFunction.minlength(
        appconstant.description
      );
    }
    if (form.address === "") {
      errors.address = ValidationConstants.addressRequired;
    } else if (form.address?.[0] === " ") {
      errors.address = ValidationFunction.validField(
        appconstant.address.toLocaleLowerCase()
      );
    } else if (form.address?.length < 3) {
      errors.address = ValidationFunction.minlength(appconstant.address);
    }
    if (
      form?.duration?.split(":")[0] === "00" &&
      form?.duration?.split(":")[1] === "00"
    ) {
      errors.duration = ValidationConstants.duration;
    }
    if (form.address === "") {
      errors.address = ValidationConstants.addressRequired;
    } else if (form.address?.[0] === " ") {
      errors.address = ValidationFunction.validField(
        appconstant.address.toLocaleLowerCase()
      );
    } else if (form.address?.length < 3) {
      errors.address = ValidationFunction.minlength(appconstant.address);
    }
    return errors;
  };
  const onChange = (obj) => {
    setDetail((state) => {
      return { ...state, ...obj };
    });
  };
  return (
    <div>
      <BreadCrumb
        firstCrumb={{
          link: constantRoutes.eventmanagement,
          pathName: appconstant.eventManagement,
        }}
        currentCrumb={appconstant.editEventDetail}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.editEventDetails}
            </h6>
          </div>
          <div className="profile-image">
            <Image
              preview={false}
              type="image"
              src={
                image
                  ? URL.createObjectURL(image)
                  : data?.image
                    ? url + data?.image
                    : images.edit
              }
              className={image && "upload_image"}
            />
            <div className="datacenter-icon">
              <input
                type="image"
                src={images.pluss}
                title={ValidationFunction.clickToImg(
                  image || data?.image ? appconstant.change : appconstant.upload
                )}
                onClick={() => refs.current.click()}
              />
              <input
                type="file"
                style={{ display: "none" }}
                ref={refs}
                onChange={imageSet}
                accept={Object.values(extentions)}
              />
            </div>
          </div>
          <div className="wrapper_line view_modules view ">
            <div className="form-group">
              <label>{appconstant.eventName}</label>
              <Textfield
                className="form-control"
                type="text"
                placeholder={appconstant.eventName}
                value={form?.name}
                onChange={(e) => inputChange({ name: e.target.value })}
                error={validate().name}
                maxLength={50}
              />
            </div>
            <div>
              <div className="form-group">
                <label>{appconstant.eventStartDate}</label>
                <DatePicker
                  value={moment(form?.startDate).local()}
                  placeholder={appconstant.eventStartDate}
                  format={time_dateFormat.dateFormat}
                  inputReadOnly={true}
                  onChange={(_, d) => inputChange({ startDate: d })}
                  disabledDate={(current) => current && current < moment()}
                />
              </div>
              <div className="form-group">
                <label>{appconstant.eventStartTime}</label>
                <TimePicker
                  placeholder={appconstant.eventStartTime}
                  value={moment(form?.startTime).local()}
                  format={time_dateFormat.timeFormat}
                  onChange={(_, t) => inputChange({ startTime: _._d })}
                  inputReadOnly={true}
                />
              </div>
              <div className="form-group">
                <label>{appconstant.TimeDuration}</label>
                <TimePicker
                  placeholder={appconstant.TimeDuration}
                  showNow={false}
                  inputReadOnly={true}
                  onChange={(_, d) => inputChange({ duration: d })}
                  format={time_dateFormat.Duration}
                  error={validate().duration}
                  value={moment(form?.duration, "HH:mm")}
                />
              </div>
              <div className="form-group">
                <label>{appconstant.eventDescription}</label>
                <TextAreafield
                  className="textArea-control"
                  type="text"
                  placeholder={appconstant.eventDescription}
                  value={form?.description}
                  onChange={(e) => inputChange({ description: e.target.value })}
                  error={validate().description}
                  maxLength={500}
                />
              </div>
              <div className="form-group">
                <label>{appconstant.eventFor}</label>
                <Select
                  style={{
                    width: 120,
                    color: '#000000'
                  }}
                  name={"type"}
                  value={eventFor}
                  onChange={(e) => setEventFor(e)}
                >
                  <Option title="" value="guest">
                    Guest
                  </Option>
                  <Option title="" value="paid">
                    Members
                  </Option>
                  <Option title="" value="all">
                    All
                  </Option>
                </Select>

              </div>

              <div className="form-group">
                <label>{appconstant.address}</label>
                <Textfield
                  className="form-control"
                  type="text"
                  placeholder={appconstant.address}
                  value={form?.address}
                  onChange={(e) => inputChange({ address: e.target.value })}
                  error={validate().address}
                  maxLength={1000}
                  onClick={() => setIsVisible(true)}
                />
              </div>
            </div>
            <div className="up-btn">
              <button onClick={update} type="submit" className="button-list">
                {appconstant.buttonupate}
              </button>
            </div>
          </div>
        </div>
      </div>
      <GooglePlacesAutoComplete isVisible={isVisible} setIsVisible={setIsVisible} onPlaceSelected={(place) => {
        let tempAddress = place?.formatted_address.split(',')
        tempAddress.pop();
        tempAddress.pop();
        inputChange({ address: tempAddress.toString() })
        setLatlng({
          lat: place?.geometry?.location?.lat(),
          lng: place?.geometry?.location?.lng()
        })
      }} />
    </div>
  );
};
export default EditEventDetails;
